import * as React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}

const FeefoBadge = ({ ...props }: Props) => (
  <div className={cn('mb-1', props.className)}>
    <a href="https://www.feefo.com/reviews/mgx-copy" target="_blank" rel="noreferrer">
      <img
        alt="Feefo logo"
        src="https://api.feefo.com/api/logo?merchantidentifier=mgx-copy"
        title="See what our customers say about us"
      />
    </a>
  </div>
);

export default FeefoBadge;
