import * as React from 'react';
import cn from 'classnames';
import css from './Heading.scss';

const H2 = ({ children, className, ...otherProps }: React.HTMLProps<HTMLHeadingElement>) => (
  <h2 className={cn(css.Heading, css.H2, className)} {...otherProps}>
    {children}
  </h2>
);

export default H2;
