import * as React from 'react';
import Reviews from '../Reviews/Reviews';

import google from 'assets/images/companies/google.png';

const googleUrl =
  'https://www.google.com/search?safe=off&sxsrf=ALeKk00ueFc1bkd3FSnQBbT6hRvL_GqJKA%3A1592933968442' +
  '&source=hp&ei=UD7yXpKvGIXb-gTljYCIBw&q=printivity+reviews&oq=printivity+reviews&gs_lcp=CgZwc3kt' +
  'YWIQAzICCAA6BAgjECc6BQgAEJECOgUIABCxAzoFCAAQgwE6BwgjELECECc6BAgAEApQswFY7Rdg-RhoAXAAeACAAaABiAH' +
  'pEJIBBDMuMTaYAQCgAQGqAQdnd3Mtd2l6&sclient=psy-ab&ved=0ahUKEwiSl9m6vZjqAhWFrZ4KHeUGAHEQ4dUDCAg&u' +
  'act=5#lrd=0x80dbfbbca9f9591f:0xd19f739d429def7c,1';

interface Props {
  horizontal?: boolean;
  totalReviews?: number;
  totalScore?: number;
  reviewsLink?: string;
  showText?: 'average' | 'score' | 'none';
  className?: string;
}

const GoogleReviews = ({
  showText = 'score',
  totalReviews = 97,
  totalScore = 5,
  reviewsLink = googleUrl,
  ...props
}: Props) => (
  <Reviews
    companyLogo={google}
    totalScore={totalScore}
    totalReviews={totalReviews}
    reviewsLink={reviewsLink}
    companyName="Google"
    horizontal={props.horizontal}
    showText={showText}
    className={props.className}
  />
);

export default GoogleReviews;
