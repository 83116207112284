import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Faq } from 'bundles/App/pages/HelpCenter/types';

export type RobotsType =
  | 'all'
  | 'noindex'
  | 'nofollow'
  | 'index'
  | 'follow'
  | 'none'
  | 'noarchive'
  | 'nosnippet'
  | 'notranslate'
  | 'noimageindex';

interface Props {
  title: string;
  description?: string;
  keywords?: string;
  canonical?: string;
  robots?: RobotsType[];
  faqs?: Faq[];
}

const createFaqJson = (faqs: Faq[]): string =>
  JSON.stringify({
    '@context': `https://schema.org`,
    '@type': 'FAQPage',
    mainEntity: faqs.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })),
  });

const Meta = ({ canonical, description, faqs, keywords, robots, title }: Props) => (
  <Helmet>
    <title>{title}</title>
    {!!description && <meta name="description" content={description} />}
    {!!keywords && <meta name="keywords" content={keywords} />}
    {!!canonical && <link rel="canonical" href={canonical} />}
    {!!robots && <meta name="robots" content={[...robots].join(',')} />}
    {!!faqs && <script type="application/ld+json">{createFaqJson(faqs)}</script>}
  </Helmet>
);
export default Meta;
