import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconStarHollow = ({
  title = 'Star hollow',
  color = 'red',
  viewBoxWidth = 15,
  viewBoxHeight = 15,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="M7.5 11.25l-4.408 2.318.842-4.91L.367 5.183l4.929-.716L7.5 0l2.204 4.466 4.929.716-3.567 3.477.842 4.909z"
      stroke={colors[color]}
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconStarHollow;
