import * as React from 'react';
import cn from 'classnames';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  content: string;
  noStyle?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const HtmlContent = ({ className, content, noStyle, onClick, ...otherProps }: Props) =>
  noStyle ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={cn(className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      {...otherProps}
    />
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={cn('prose-a:text-blue hover:prose-a:text-blue-900', className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
      onClick={onClick}
      role="button"
      tabIndex={0}
      {...otherProps}
    />
  );

export default HtmlContent;
