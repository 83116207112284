import * as React from 'react';
import ReviewStars from 'styleguide/components/ReviewStars/ReviewStars';
import { UiColor } from 'styleguide/styles/colors';

interface Props {
  totalReviews: number;
  totalScore: number;
  url: string;
  color?: UiColor;
  horizontal?: boolean;
  showAverage?: boolean;
  showText: 'average' | 'score' | 'none';
}

const ReviewStarsRow = ({ showText, totalReviews, ...props }: Props) => (
  <div className="flex items-center md:justify-between -md:flex-col -md:justify-center">
    {showText === 'average' && <div>{`${props.totalScore.toFixed(2)} Average`}</div>}
    {showText === 'score' && (
      <div className="paragraph-desktop mr-1 mt-1 text-gray-500">
        {totalReviews === 1 ? '1 Review' : `${totalReviews} Reviews`}
      </div>
    )}
    <ReviewStars count={props.totalScore} max={5} color={props.color ? props.color : null} />
  </div>
);

export default ReviewStarsRow;
