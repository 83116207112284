import * as React from 'react';
import cn from 'classnames';
import H2 from 'styleguide/components/Heading/H2';
import FeefoBadge from 'bundles/App/pages/HomePage/Wow/FeefoBadge/FeefoBadge';
import YelpReviews from 'bundles/App/pages/HomePage/Wow/YelpReviews';
import GoogleReviews from 'bundles/App/pages/HomePage/Wow/GoogleReviews';
import FacebookReviews from 'bundles/App/pages/HomePage/Wow/FacebookReviews';

interface Props {
  withoutBorder?: boolean;
}

const Reviews = ({ withoutBorder }: Props) => (
  <div
    className={cn(
      !withoutBorder && 'border-y border-solid border-gray-50',
      'mx-auto my-8 flex max-w-screen-2xl flex-col py-16 xs:px-6 md:px-[100px] lg:my-16 3xl:px-0',
    )}
  >
    <H2 className="mb-4 text-left !font-hvBold !text-4xl !leading-10 !tracking-normal -sm:text-center">
      America&apos;s Highest Rated Online Printing Company
    </H2>
    <div className="flex items-center justify-between -lg:mt-10 -lg:flex-col -lg:justify-center">
      <FeefoBadge className="!mb-0 lg:self-end" />
      <YelpReviews showText="none" />
      <GoogleReviews showText="none" />
      <FacebookReviews showText="none" />
    </div>
  </div>
);

export default Reviews;
