import * as React from 'react';
import Reviews from '../Reviews/Reviews';
import { socialPath } from 'bundles/App/routes';

import facebook from 'assets/images/companies/facebook.png';

interface Props {
  horizontal?: boolean;
  totalReviews?: number;
  totalScore?: number;
  reviewsLink?: string;
  showText?: 'average' | 'score' | 'none';
  className?: string;
}

const FacebookReviews = ({
  showText = 'score',
  totalReviews = 64,
  totalScore = 5,
  reviewsLink = `${socialPath.facebook}/reviews`,
  ...props
}: Props) => (
  <Reviews
    companyLogo={facebook}
    totalScore={totalScore}
    totalReviews={totalReviews}
    reviewsLink={reviewsLink}
    companyName="Facebook"
    horizontal={props.horizontal}
    showText={showText}
    className={props.className}
  />
);

export default FacebookReviews;
