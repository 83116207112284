import * as React from 'react';
import cn from 'classnames';
import Image from 'styleguide/components/Image/Image';
import ReviewStarsRow from './ReviewStarsRow';
import { UiColor } from 'styleguide/styles/colors';
import A from 'styleguide/components/Links/A';

interface Props {
  companyLogo: string;
  totalScore: number;
  totalReviews: number;
  reviewsLink: string;
  companyName: string;
  starsColor?: UiColor;
  horizontal?: boolean;
  showText: 'average' | 'score' | 'none';
  className?: string;
}

const Reviews = ({ showText = 'score', ...props }: Props) => (
  <div className="mt-5 md:mt-16">
    <A href={props.reviewsLink} color="black" underline="none" targetBlank>
      <div
        className={cn(
          props.horizontal
            ? 'flex h-24 w-48 flex-row items-center justify-end'
            : 'm-auto flex h-24 w-52 flex-col justify-end',
          showText === 'none' && 'items-center',
        )}
      >
        <Image
          url={props.companyLogo}
          title={props.companyName}
          alt={props.companyName}
          className="mx-auto mb-1 h-16 w-32 object-contain"
        />
        <ReviewStarsRow
          totalReviews={props.totalReviews}
          totalScore={props.totalScore}
          url={props.reviewsLink}
          color={props.starsColor ? props.starsColor : 'blue'}
          horizontal={props.horizontal}
          showText={showText}
        />
      </div>
    </A>
  </div>
);

export default Reviews;
